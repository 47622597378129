
 const productsData =
 [
    {
        "name": "Button Down Shirts ",
        "description": "Alimens & Gentle Mens Short Sleeve Dress Shirts Wrinkle Free Solid Casual Button Down Shirts with Pocket",
        "unique_id": "HA202718P",
        "url_slug": "button-down-shirts",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "cc84caeccc814808b01ca497cbe0603e",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-15T05:03:13",
        "last_updated": "2024-07-15T05:03:22",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "cc84caeccc814808b01ca497cbe0603e",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "36eeda9b62e2471f887b9b0ae9b0eb96.jpg",
                "url": "hanifah-ali/product_button_down_shirts_71b1ba_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    19.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Golf Skorts ",
        "description": "BLEVONH Womens Summer Tennis Skirts with Shorts Underneath, Pickleball, Athletic, Workout and Golf Skorts with Pockets",
        "unique_id": "HA202717P",
        "url_slug": "golf-skorts",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "493d73c6e2be4461a544f9df13f996f7",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-15T05:00:55",
        "last_updated": "2024-07-15T05:01:03",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "493d73c6e2be4461a544f9df13f996f7",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "49a63c620cc040949f67558ac30c218a.jpg",
                "url": "hanifah-ali/product_golf_skorts_7d7720_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    23.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 5.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Mini Skater Skirt ",
        "description": "Hanna Nikole Women Plus Size Casual Mini Skater Skirt Basic Versatile Tennis Skirt for Women Skorts Skirts with Pockets",
        "unique_id": "HA202715P",
        "url_slug": "casual-mini-skater-skirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "88dd96a0de6d4ab9b7092d2a33b5681e",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-15T04:56:22",
        "last_updated": "2024-07-15T04:56:39",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "88dd96a0de6d4ab9b7092d2a33b5681e",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "0f7cbb0fc06840b09deaf215de4af17d.jpg",
                "url": "hanifah-ali/product_casual_mini_skater_skirt_34e135_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    35.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 9.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Tungsten Rings  ",
        "description": "100S JEWELRY Tungsten Rings For Men Wedding Band White Gold Brick Pattern Rhodium Plated Size 6-16",
        "unique_id": "HA196304P",
        "url_slug": "tungsten-rings",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "2604fcfe792748e8a4d83124b6c24453",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:21:20",
        "last_updated": "2024-07-13T05:21:38",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "2604fcfe792748e8a4d83124b6c24453",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "69b9661391e3422f9faa50b42ae4eb08.jpg",
                "url": "hanifah-ali/product_tungsten_rings_347d39_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    45.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 13.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Cross Leather Bracelet",
        "description": "Cross Leather Bracelet for Men Women 316L Stainless Steel with 18K Gold Plated Christ Jesus Multi-layer Handmade Bracelet,7.5/8/9 Inches",
        "unique_id": "HA196301P",
        "url_slug": "cross-leather-bracelet",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "ca488875e8e14c189c67ebbf9e3eebae",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:19:28",
        "last_updated": "2024-07-13T05:19:39",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "ca488875e8e14c189c67ebbf9e3eebae",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "e253d5c97cbc4af9a651db3fc90597ed.jpg",
                "url": "hanifah-ali/product_cross_leather_bracelet_2200b5_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    231.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 7.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Willis Judd Bracelet",
        "description": "Willis Judd Bracelets For Men Gold Style Bracelets For Men Titanium Mens Bracelet Black Carbon Fiber. Size Adjustable.",
        "unique_id": "HA196297P",
        "url_slug": "willis-judd-bracelet",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "e47ee9ec2e5a4ef2969b49abc0b1d6b9",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:17:37",
        "last_updated": "2024-07-13T05:18:13",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "e47ee9ec2e5a4ef2969b49abc0b1d6b9",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "0187ce0767204814870adb8d86f6e309.jpg",
                "url": "hanifah-ali/product_willis_judd_bracelet_119989_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    123.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Heart Necklace ",
        "description": "Heart Necklace for Women, 925 Sterling Silver Diamond Pendant Necklaces ",
        "unique_id": "HA196289P",
        "url_slug": "heart-necklace-for-women-925-sterling-silver-diamond-pendant-necklaces-for-women",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "eca1a9df60fd4443a98c482ad6476847",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:13:22",
        "last_updated": "2024-07-13T05:13:53",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "eca1a9df60fd4443a98c482ad6476847",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "a90f4847f9a04aa68070deee563b0364.jpg",
                "url": "hanifah-ali/product_heart_necklace_for_women_925_sterling_silver_diamond_pendant_necklaces_for_women_313f63_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    45.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 1.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Rose Birthstone  ",
        "description": "Necklaces for Women Sterling Silver Rose Flower Heart Pendant Necklace",
        "unique_id": "HA196286P",
        "url_slug": "rose-birthstone-necklaces-for-women-sterling-silver-rose-flower-heart-pendant-necklace",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "9963e5055c924773bef8eb5102973b8e",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:11:44",
        "last_updated": "2024-07-13T05:12:02",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "9963e5055c924773bef8eb5102973b8e",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "e9c5de97716b4d5d9b93f70610e7a4ff.jpg",
                "url": "hanifah-ali/product_rose_birthstone_necklaces_for_women_sterling_silver_rose_flower_heart_pendant_necklace_888003_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    78.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Leafael Necklaces ",
        "description": "Leafael Necklaces for Women, Infinity Love Heart Pendant with Birthstone Crystals.",
        "unique_id": "HA196284P",
        "url_slug": "leafael-necklaces",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "24a5943334fe48e38558480add57077f",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:10:09",
        "last_updated": "2024-07-13T05:10:38",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "24a5943334fe48e38558480add57077f",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "8efbc4efc404497e80c46f3ef2f4fc58.jpg",
                "url": "hanifah-ali/product_leafael_necklaces_a8e074_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    24.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 2.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Black Pearl Necklaces",
        "description": "Gifts Anniversary for Wife Women, Tahitian Black Pearl Necklaces.",
        "unique_id": "HA196274P",
        "url_slug": "black-pearl-necklaces",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "47dc8b6ca6ca4f4cbd96bb73ccc459ae",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:07:38",
        "last_updated": "2024-07-13T05:07:54",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "47dc8b6ca6ca4f4cbd96bb73ccc459ae",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "5c2a4cfccd414783b6b1eaf7b159b3f7.jpg",
                "url": "hanifah-ali/product_black_pearl_necklaces_aca70d_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    56.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 2.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Heart Pendant Necklace  ",
        "description": "STARCHENIE Heart Birthstone Pendant Necklace 925 Sterling Silver Jewelry Gift for Women",
        "unique_id": "HA196265P",
        "url_slug": "heart-birthstone-pendant-necklace",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "3856c6af944b442a9778d5412c78f358",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:05:07",
        "last_updated": "2024-07-13T06:24:59",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "3856c6af944b442a9778d5412c78f358",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "86fa99128ccf49658948fe28dea6a62d.jpg",
                "url": "hanifah-ali/product_heart_birthstone_pendant_necklace_3eb709_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    55.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 4.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Love Heart Pendant ",
        "description": "Leafael Necklaces for Women, Infinity Love Heart Pendant with Birthstone Crystals.",
        "unique_id": "HA196252P",
        "url_slug": "leafael-necklaces-for-women-infinity-love-heart-pendant",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "1a8cee16f63848e4ba40096aba8aabfa",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "jewelries",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:46",
                "id": "85bab887b85e4ce383cd6f4fd78c2ab5",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:46",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T05:00:58",
        "last_updated": "2024-07-13T05:01:46",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "1a8cee16f63848e4ba40096aba8aabfa",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "c6368bf49f084a5b812490748f0b2295.jpg",
                "url": "hanifah-ali/product_leafael_necklaces_for_women_infinity_love_heart_pendant_de28bb_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    54.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": " Dry Stretch Joggers  ",
        "description": "Rapoo Mens Lightweight Hiking Pants Quick Dry Stretch Joggers for Running Workout Casual",
        "unique_id": "HA196238P",
        "url_slug": "dry-stretch-joggers",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "80843cb8ffd74ed0b355352a84a26b61",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:55:21",
        "last_updated": "2024-07-13T04:56:22",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "80843cb8ffd74ed0b355352a84a26b61",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "fd5137b0709d4ca9a54cccf1642942c6.jpg",
                "url": "hanifah-ali/product_dry_stretch_joggers_5d56bf_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    56.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "ZPB Men's Polo Shirt  ",
        "description": "ZPB Men's Polo Shirt and Shorts Set Summer Outfits tracksuit Fashion Casual Short Sleeve Polo Suit for Men 2 Piece Short Set",
        "unique_id": "HA196222P",
        "url_slug": "zpb-men-s-polo-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "687182084aba473c856ea1802632c330",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:48:35",
        "last_updated": "2024-07-13T04:49:38",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "687182084aba473c856ea1802632c330",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "32c8c57625664161a5bd1978eac3cf92.jpg",
                "url": "hanifah-ali/product_zpb_men_s_polo_shirt_e798d2_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    45.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 4.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Casual 2 Piece ",
        "description": "Men's Summer Beach Vacation Outfit Set with Breathable Fabric Fashion Casual 2 Piece Short Set for Men",
        "unique_id": "HA196218P",
        "url_slug": "casual-2-piece",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "a3656844a46e433988bd8f917394fbfa",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:45:10",
        "last_updated": "2024-07-13T04:45:25",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "a3656844a46e433988bd8f917394fbfa",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "6d035089a81a47c18da1681bbb0ebeb7.jpg",
                "url": "hanifah-ali/product_casual_2_piece_f0f1b8_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    23.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 4.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": " Casual 2 Piece Set",
        "description": "Men's Summer Beach Vacation Outfit Set with Breathable Fabric Fashion Casual 2 Piece Short Set for Men",
        "unique_id": "HA196215P",
        "url_slug": "casual-2-piece-short-set",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "c9e4646f0c504580855448ec2a6f442c",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [],
        "date_created": "2024-07-13T04:43:01",
        "last_updated": "2024-07-13T06:25:32",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "c9e4646f0c504580855448ec2a6f442c",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "3033d18bf76c49ba976f60b7fa912bb3.jpg",
                "url": "hanifah-ali/product_casual_2_piece_short_set_79db90_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    33.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 0.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Men's Summer 2 Piece",
        "description": "Men's Summer 2 Piece Set Casual Cotton Linen Printed Vacation Outfits with Pockets\r\n",
        "unique_id": "HA196214P",
        "url_slug": "men-s-summer-2-piece",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "66ab200291cb48d68c23728753517129",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:40:57",
        "last_updated": "2024-07-13T04:41:11",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "66ab200291cb48d68c23728753517129",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "a1ac8f4598df4212a66cccbd7769eb34.jpg",
                "url": "hanifah-ali/product_men_s_summer_2_piece_ca92c0_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    38.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Men's 2 Piece ",
        "description": "COOFANDY Men's 2 Piece Outfit Casual Short Sleeve Button Down Shirt Beach Summer Loose Pant Sets",
        "unique_id": "HA196211P",
        "url_slug": "men-s-2-piece",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "72fc893ab24f498fa4eaf623cfa371dd",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:37:34",
        "last_updated": "2024-07-13T04:37:51",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "72fc893ab24f498fa4eaf623cfa371dd",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "bf4385844bca4cddabd2eb26a47213aa.jpg",
                "url": "hanifah-ali/product_men_s_2_piece_c6f9b3_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    87.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Mens Short Sleeve",
        "description": "COOFANDY Mens Short Sleeve Casual Button Down Shirts Summer Untucked Dress Shirts with Pocket",
        "unique_id": "HA196209P",
        "url_slug": "mens-short-sleeve",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "132a0aa4826940d88692fe4ba62c1cbe",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:34:30",
        "last_updated": "2024-07-13T04:34:48",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "132a0aa4826940d88692fe4ba62c1cbe",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "e76cfd6bbefb48e6976d7f27d13d8f03.jpg",
                "url": "hanifah-ali/product_mens_short_sleeve_713f7a_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    45.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 8.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": " Linen Shirts ",
        "description": "COOFANDY Men's Linen Shirts Short Sleeve Casual Shirts Button Down Shirt for Men Beach Summer Wedding Shirt",
        "unique_id": "HA196206P",
        "url_slug": "linen-shirts",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "c342c2b2ac9444ecaac172346a9aaecb",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:32:59",
        "last_updated": "2024-07-13T04:33:17",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "c342c2b2ac9444ecaac172346a9aaecb",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "36fde3ce09f947c08dc2f83a3e1d0062.jpg",
                "url": "hanifah-ali/product_linen_shirts_8d31fb_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    23.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 8.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Beach Linen Shirt",
        "description": "COOFANDY Men's Cuban Guayabera Shirt Short Sleeve Button Down Shirts Casual Summer Beach Linen Shirts",
        "unique_id": "HA196198P",
        "url_slug": "beach-linen-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "d7400d409e744c09b0b20f083d674b00",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:30:36",
        "last_updated": "2024-07-13T04:30:44",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "d7400d409e744c09b0b20f083d674b00",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "26accc45de4c4e1290e7380441cb9b69.jpg",
                "url": "hanifah-ali/product_beach_linen_shirt_8e5a7d_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    98.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 6.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Men's Dress Shirt",
        "description": "Van Heusen Men's Dress Shirt Regular Fit Poplin Solid\r\n",
        "unique_id": "HA196192P",
        "url_slug": "men-s-dress-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "2be807ff22ed4a5f8c8c08a86cff089d",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:29:03",
        "last_updated": "2024-07-13T04:29:23",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "2be807ff22ed4a5f8c8c08a86cff089d",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "77b7efb60fba4157a4794613a53db4ca.jpg",
                "url": "hanifah-ali/product_men_s_dress_shirt_925ce1_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    89.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 1.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Men's Necktie",
        "description": "Adulove Men's Necktie Classic Silk Tie Woven Jacquard Neck Ties",
        "unique_id": "HA196184P",
        "url_slug": "men-s-necktie",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "f818e21d98f34e6db6a51cab4f9c07bd",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:27:07",
        "last_updated": "2024-07-13T04:28:25",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "f818e21d98f34e6db6a51cab4f9c07bd",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "f613b610a7d64ff5916e6e661c97dbf8.jpg",
                "url": "hanifah-ali/product_men_s_necktie_3f5aa5_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    48.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 1.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Dry Golf Polo Shirt",
        "description": "Essentials Men's Regular-Fit Quick-Dry Golf Polo Shirt\r\n",
        "unique_id": "HA196176P",
        "url_slug": "dry-golf-polo-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "63cdfe0e5e73429993204b412753f460",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "men",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:29",
                "id": "30ee5d7d186e46028946a044cc796e43",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:29",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:24:42",
        "last_updated": "2024-07-13T04:25:08",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "63cdfe0e5e73429993204b412753f460",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "cc5a71ad38854af9bdae2c622ea20180.jpg",
                "url": "hanifah-ali/product_dry_golf_polo_shirt_b69fc0_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    15.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 5.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Basic Tank Shirt",
        "description": "Ekouaer Womens Silk Satin Tank Tops V Neck Casual Cami Sleeveless Camisole Blouses Summer Basic Tank Shirt",
        "unique_id": "HA196163P",
        "url_slug": "basic-tank-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "f96ccfd9962543429061fe501763e329",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:21:06",
        "last_updated": "2024-07-13T04:21:27",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "f96ccfd9962543429061fe501763e329",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "63ac459b08f142cdb5658855696a0c0d.jpg",
                "url": "hanifah-ali/product_basic_tank_shirt_74f90a_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    15.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 16.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Off Shoulder Dress",
        "description": "Womens Off Shoulder Formal Dresses Sparkly Gorgeous Golden Silk Embroidery Gowns and Evening Party Long Dress with Split",
        "unique_id": "HA196152P",
        "url_slug": "off-shoulder-dress",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "13ad20c8c1c44f26b467702586e1bd1f",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:17:50",
        "last_updated": "2024-07-13T04:18:09",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "13ad20c8c1c44f26b467702586e1bd1f",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "b91c0bc3a6194c98b0b61c25f56bbdc9.jpg",
                "url": "hanifah-ali/product_off_shoulder_dress_1f68a7_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    124.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 4.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Homecoming Dress",
        "description": "Women's Formal Deep-V Neck Tulle Long Prom Gown, Sequin Spaghetti Straps Lace-up Homecoming Dress",
        "unique_id": "HA196144P",
        "url_slug": "homecoming-dress",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "c90eb78e252845f1adfcda1aad4c714a",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:15:01",
        "last_updated": "2024-07-13T04:15:17",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "c90eb78e252845f1adfcda1aad4c714a",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "fc96eb6b1d2448be91e93164eee95c28.jpg",
                "url": "hanifah-ali/product_homecoming_dress_3f0a7e_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": null
            }
        ],
        "is_deleted": false,
        "available_quantity": 1.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Formal Evening Gown",
        "description": "Women's Long Cold Shoulder Bridesmaid Dress with Slit Ruffled Chiffon Prom Dresses Formal Evening Gown",
        "unique_id": "HA196141P",
        "url_slug": "formal-evening-gown",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "230f7ab482414bd996547c8f27618818",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [],
        "date_created": "2024-07-13T04:13:30",
        "last_updated": "2024-07-13T04:13:42",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "230f7ab482414bd996547c8f27618818",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "49fbb9cddb3c4caf8ac311dcf522a478.jpg",
                "url": "hanifah-ali/product_formal_evening_gown_fa94c9_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    62.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 2.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": " Cami Dress ",
        "description": "MakeMeChic Women's Floral Print Shirred Cami Dress Spaghetti Strap Sleeveless High Waist Flare Long Dress",
        "unique_id": "HA196138P",
        "url_slug": "cami-dress",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "5daa6ff90ea74e46a336e906d0a79e1d",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:11:54",
        "last_updated": "2024-07-13T04:12:04",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "5daa6ff90ea74e46a336e906d0a79e1d",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "90e69756301d49eb8e4be9c9ac853a58.jpg",
                "url": "hanifah-ali/product_cami_dress_187673_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    32.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 40.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Evaless Sweater Vest ",
        "description": "EVALESS Sweater Vest for Women Striped Color Pullover Tank Top Round Neck Cap Sleeve Lightweight 2024 Summer Casual Clothes",
        "unique_id": "HA196137P",
        "url_slug": "evaless-sweater-vest",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "4f046ee5d79149579ab688ed0e37c53d",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:09:45",
        "last_updated": "2024-09-10T03:55:57",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "4f046ee5d79149579ab688ed0e37c53d",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "01e1d9e3d28346c3b2252700640160ba.jpg",
                "url": "hanifah-ali/product_evaless_sweater_vest_1268f5_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    19.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 7.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Kimono Cardigan ",
        "description": "Women's Floral Print Puff Sleeve Kimono Cardigan Loose Cover Up Casual Blouse Tops",
        "unique_id": "HA196136P",
        "url_slug": "puff-sleeve-kimono-cardigan",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "34ce69d161a34af6a9191e6eb68ca447",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:07:34",
        "last_updated": "2024-07-13T06:25:49",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "34ce69d161a34af6a9191e6eb68ca447",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "6a999a2005be4e13b3c0b41ad1046fcc.jpg",
                "url": "hanifah-ali/product_puff_sleeve_kimono_cardigan_d43b3f_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    32.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 4.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Ekouaer Turtleneck Shirt ",
        "description": "Ekouaer Turtleneck Shirt Women Ruffle Ribbed Long Sleeve Mock Neck Tops Slim Fit Lettuce Trim Base Layer.",
        "unique_id": "HA196132P",
        "url_slug": "ekouaer-turtleneck-shirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "4ff8894a9f32408a9ad8f20f15d2d9ca",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:04:46",
        "last_updated": "2024-07-13T04:05:03",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "4ff8894a9f32408a9ad8f20f15d2d9ca",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "5ccf93ac8b32499e98ecf59a6aa27f45.jpg",
                "url": "hanifah-ali/product_ekouaer_turtleneck_shirt_6695fa_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    14.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 5.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Cargo Skirt ",
        "description": "Cargo Skirt Women y2k Button Mini Jeans Skirt Denim Skirt with Pocket Low Waist",
        "unique_id": "HA196127P",
        "url_slug": "cargo-skirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "dd2d95f9eb8942a38670e0eb00137d4d",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T04:00:35",
        "last_updated": "2024-07-13T04:00:59",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "dd2d95f9eb8942a38670e0eb00137d4d",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "7113c993b6f342caa7ea0f3fd582f67a.jpg",
                "url": "hanifah-ali/product_cargo_skirt_478342_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    31.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 3.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Mini Lingerie Skirts",
        "description": "Avidlove Women's Pleated Skater Skirt Basic Casual High Waisted Ruffles Flared Mini Lingerie Skirts",
        "unique_id": "HA196118P",
        "url_slug": "mini-lingerie-skirts",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "ca3839f50e424e859d9c1d37ed781a25",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [],
        "date_created": "2024-07-13T03:58:07",
        "last_updated": "2024-07-13T03:58:27",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "ca3839f50e424e859d9c1d37ed781a25",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "86c561704291443dacd2329d703fc987.jpg",
                "url": "hanifah-ali/product_mini_lingerie_skirts_742964_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    29.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 2.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "High Waisted Skirt",
        "description": null,
        "unique_id": "HA196114P",
        "url_slug": "high-waisted-skirt",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "fae4f814754f4d7189810644be81a04a",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T03:56:02",
        "last_updated": "2024-07-13T03:56:02",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "fae4f814754f4d7189810644be81a04a",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "e6c7154a4d354e84a460a05461aac10c.jpg",
                "url": "hanifah-ali/product_high_waisted_skirt_2c78db_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    17.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 28.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    },
    {
        "name": "Flared Midi Skirts",
        "description": "Yincro Women's A-Line Pockets Skirt High Waist Flared Midi Skirts",
        "unique_id": "HA196103P",
        "url_slug": "flared-midi-skirts",
        "is_available": true,
        "is_service": false,
        "previous_url_slugs": null,
        "unavailable": false,
        "unavailable_start": null,
        "unavailable_end": null,
        "id": "f81c60fc884740818b1c0bd11f5ceaff",
        "parent_product_id": null,
        "parent": null,
        "organization_id": "e85c76b135534056949dbe1e331bfd70",
        "product_image": [],
        "categories": [
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "women",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:46:36",
                "id": "656d261afeff4c88971f65ce0a5b849f",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:46:36",
                "subcategories": [],
                "parents": []
            },
            {
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "name": "all",
                "position": null,
                "category_type": "standard",
                "description": "",
                "last_updated": "2024-07-13T00:47:48",
                "id": "a2abf2601fd64f3596bc446b29f2fd1a",
                "parent_id": null,
                "url_slug": null,
                "is_deleted": false,
                "date_created": "2024-07-13T00:47:48",
                "subcategories": [],
                "parents": []
            }
        ],
        "date_created": "2024-07-13T03:51:20",
        "last_updated": "2024-07-13T05:33:54",
        "user_id": "0ee9131e13b0425f804624d5b632dda5",
        "photos": [
            {
                "model_name": "products",
                "model_id": "f81c60fc884740818b1c0bd11f5ceaff",
                "organization_id": "e85c76b135534056949dbe1e331bfd70",
                "filename": "d1cdc664bff54d1c8673a3369dc9cb43.jpg",
                "url": "hanifah-ali/product_flared_midi_skirts_ac94e5_1.jpg",
                "is_featured": false,
                "save_as_jpg": true,
                "is_public": true,
                "file_rename": false,
                "position": 1
            }
        ],
        "current_price": [
            {
                "USD": [
                    24.0,
                    null,
                    []
                ]
            }
        ],
        "is_deleted": false,
        "available_quantity": 7.0,
        "selling_price": null,
        "discounted_price": null,
        "buying_price": null,
        "extra_infos": null
    }
    ]

    export default productsData;